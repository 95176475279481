import classNames from 'classnames';
import { ReactElement } from 'react';

import { Icon } from 'display-components/icon-list/icon-list';

import UIStatuses from 'types/ui-statuses';

import './badge-status.style.scss';

export interface BadgeStatusProps extends React.HTMLAttributes<HTMLSpanElement> {
    label: string;
    variant?: UIStatuses;
    className?: string;
    icon?: Icon;
}

const BadgeStatus = ({ label, variant, className, icon, ...props }: BadgeStatusProps): ReactElement => {
    const containerClasses = classNames(
        'badge-status',
        { [`${className}`]: className },
        { [`badge-status--${variant}`]: variant }
    );

    return (
        <span {...props} className={containerClasses}>
            {icon?.icon && <span>{icon?.icon}</span>} {label}
        </span>
    );
};

export default BadgeStatus;
