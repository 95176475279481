import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import { drugDescriptionRoutine } from 'state/drug/drug.routines';
import { drugDescriptionSelectorV2 } from 'state/drug/drug.selectors';

import './modal-contents.styles.scss';

interface DrugInfoModalProps {
    gpi: number;
    ndc: number;
}

const DrugInfoModal = ({ gpi, ndc }: DrugInfoModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading, drugDescription } = useSelector(drugDescriptionSelectorV2);

    const drugDescriptionBody = useMemo(() => {
        if (drugDescription) {
            const drugDescriptionHtml = document.createElement('html');
            drugDescriptionHtml.innerHTML = drugDescription;
            return drugDescriptionHtml.getElementsByTagName('body')[0];
        }
        return undefined;
    }, [drugDescription]);

    useEffect(() => {
        dispatch(
            drugDescriptionRoutine.trigger({
                gpi,
                ndc
            })
        );
    }, [gpi, ndc, dispatch]);

    return (
        <div className="modal-drug-info">
            <div className="modal-drug-info-header">
                <div className="half-pill"></div>
                <h6 className="title">{t('pages.discountCard.pharmacyResults.prescriptionForm.link')}</h6>
            </div>
            {isLoading ? (
                <div className="modal-drug-info-content__loading">Loading...</div>
            ) : (
                <>
                    {drugDescriptionBody && (
                        <div
                            className="modal-drug-info-content"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(drugDescriptionBody.innerHTML, {
                                    allowedAttributes: {
                                        ...sanitizeHtml.defaults.allowedAttributes,
                                        '*': ['class']
                                    }
                                })
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default DrugInfoModal;
